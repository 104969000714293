<div class="container-fluid d-none d-sm-block">
   <div class="row">
      <div class="col-12 mapper" style="height: 450px; overflow: hidden;">
         <div class="label" style="color: #707070;">
            <p>Tresckowtraße 18a <br /> 30457 Hannover</p>
            <p>Tel.: 0511 93 68 113 <br />Fax: 0511 93 68 113 9</p>
            <p>E-Mail:<br />info@trettin-sanitätshaus.de</p>
            <p class="d-xl-none">
               Öffnungszeiten: <br>
               Mo-Fr 9-15 Uhr, <br>später nach Terminvereinbarung
            </p>
            <p class="d-none d-xl-block">
               Öffnungszeiten: <br>
               Mo-Fr 9-15 Uhr, später nach Terminvereinbarung
            </p>
         </div>
      </div>
   </div>
</div>

<div class="container d-block d-sm-none" style="margin-top: -30px; margin-bottom: 40px;">
   <div class=" row">
      <div class="col-12 mapper-mobile" style="height: 150px; overflow: hidden;">
         <!-- map -->
      </div>
   </div>
   <div class="row" style="font-size: 14px;">
      <div class="col-12" style="padding-left: 50px; color: #707070;">
         <br>
         <p>Tresckowstraße 18a <br /> 30457 Hannover</p>
         <p>
            Öffnungszeiten: <br>
            Mo-Fr 9-15 Uhr, <br> später nach Terminvereinbarung
         </p>
         <p>Tel.: 0511 93 68 113 <br />Fax: 0511 93 68 113 9</p>
         <p>E-Mail:<br />info@trettin-sanitätshaus.de</p>

      </div>
   </div>
</div>
