<div class="container">
   <div class="row">
      <div class="col-12">
         <!-- <h1 class="section-headline ">Wir sind für Sie da! <br /> Ihr Trettin Sanitätshaus in Mühlenberg/Wettbergen</h1> -->
         <p class="h1 section-headline text-center">
            Ihr Trettin Sanitätshaus in
            Mühlenberg/Wettbergen
         </p>
         <p class="section-text">
            In der Tresckowstrasse 18 finden Sie unser Sanitätshaus mit unserem umfangreichen Sortiment an Pflege-,
            Sanitäts- und Rehaartikeln.
            Wir stehen mit unseren Angeboten und Produkten zu Ihrer Verfügung und bieten Ihnen weiterhin kostenlosen Rat
            für Pflegebedürftige und ihre Angehörigen. Sie können sich vor Ort informieren und beraten lassen. Alle
            Betroffenen bekommen bei uns jederzeit Hilfe und Informationen: Ob Auskunft über Ansprüche auf Leistungen
            der Pflegekassen, über Hilfsmittel für die praktische Pflege oder wertvolle Hinweise zu lokalen
            Dienstleistungen, wie sie jetzt benötigt werden. Bei unserem speziell geschulten Personal finden Sie bei uns
            jederzeit ein offenes Ohr, Verständnis für Ihre Situation und praktische Tipps für die Pflege zu Hause.
         </p>
      </div>
   </div>
</div>