<div class="container">
   <div class="row">
      <div class="col-12">
         <p class="section-headline h1 text-center">Ihre Ansprechpartner</p>
      </div>
   </div>
</div>
<!-- groeßer als sm -->
<div class="container d-none d-md-block" style="margin-top: 30px; margin-bottom: 100px; ">
   <div class="row">
      <div class="col-3">
         <img class="MAimage" src="../../assets/DA.jpg" style="width: 100%; border-right: 2px solid #00ADC6;">
      </div>
      <div class="col-6">
         <div class="row MAtext" style="height: 50%;">
            <div class="col-12">
               <h1 class="MA-name" style="font-weight: lighter; color: #00ADC6;">Anne Dörger</h1>
               <p class="MA-text">PTA, Leitung für Reha- und Pflegebedarf<br />doerger@trettin-sanitätshaus.de</p>
            </div>
         </div>
         <div class="row MAtext" style="height: 50%;">
            <div class="col-12 text-right">
               <h1 class="MA-name" style="font-weight: lighter; color: #00ADC6;">Olga Rahenkamp</h1>
               <p class="MA-text">PTA, Leitung für Reha- und Pflegebedarf<br />rahenkamp@trettin-sanitätshaus.de</p>
            </div>
         </div>
      </div>
      <div class="col-3">
         <img class="MAimage" src="../../assets/RO.jpg" style="width: 100%; border-left: 2px solid #00ADC6;">
      </div>
   </div>
</div>

<!-- sm -->
<div class="container-fluid d-none d-sm-block d-md-none" style="margin-top: 30px; margin-bottom: 100px; ">
   <div class="row">
      <div class="col-3">
         <img class="MAimage" src="../../assets/DA.jpg" style="width: 100%; border-right: 2px solid #00ADC6;">
      </div>
      <div class="col-6">
         <div class="row MAtext" style="height: 50%;">
            <div class="col-12">
               <h1 class="MA-name" style="font-weight: lighter; color: #00ADC6;">Anne Dörger</h1>
               <p class="MA-text">PTA, Leitung für Reha- und Pflegebedarf<br />doerger@trettin-sanitätshaus.de</p>
            </div>
         </div>
         <div class="row MAtext" style="height: 50%;">
            <div class="col-12 text-right">
               <h1 class="MA-name" style="font-weight: lighter; color: #00ADC6;">Olga Rahenkamp</h1>
               <p class="MA-text">PTA, Leitung für Reha- und Pflegebedarf<br />rahenkamp@trettin-sanitätshaus.de</p>
            </div>
         </div>
      </div>
      <div class="col-3">
         <img class="MAimage" src="../../assets/RO.jpg" style="width: 100%; border-left: 2px solid #00ADC6;">
      </div>
   </div>
</div>


<!-- kleiner als sm  -->
<div class="container d-block d-sm-none" style="padding: 10px; margin-bottom: 0px">
   <div class="row">
      <div class="col-4" style="padding-right:10px;">
         <img class="MAimage" src="../../assets/DA.jpg" style="width: 100%; border-right: 2px solid #00ADC6;">
      </div>
      <div class="col-8" style="padding-left: 0px;">
         <h1 class="MA-name" style="font-weight: lighter; color: #00ADC6;">Anne Dörger</h1>
         <p class="MA-text">PTA, Leitung für Reha- und Pflegebedarf<br />doerger@trettin-sanitätshaus.de</p>
      </div>
   </div>
</div>
<div class="container d-block d-sm-none" style="padding: 10px;">
   <div class="row">

      <div class="col-8" style="padding-right: 0px;">
         <h1 class="MA-name" style="font-weight: lighter; color: #00ADC6;">Olga Rahenkamp</h1>
         <p class="MA-text">PTA, Leitung für Reha- und Pflegebedarf<br />rahenkamp@trettin-sanitätshaus.de</p>
      </div>

      <div class="col-4" style="padding-left: 10px;">
         <img class="MAimage" src="../../assets/RO.jpg" style="width: 100%; border-left: 2px solid #00ADC6;">
      </div>
   </div>
</div>