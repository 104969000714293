<div class="container" style="margin-top: 30px;">
   <div class="row">
      <div class="col-12">
         <p class="text-center h1 footer-headline">Weitere Links und Shops</p>
      </div>
   </div>
</div>


<!-- lg und größer -->
<div class="container d-none d-lg-block d-xl-block">
   <div class="row">
      <div class="col-12">
         <a href="https://www.sanivita.de/trettin"><img src="../../assets/b3.png" style="width: 100%;"></a>
      </div>
   </div>
</div>

<!-- kleiner als lg -->
<div class="container-fluid d-block d-lg-none">
   <div class="row">
      <div class="col-12">
         <a href="https://www.sanivita.de/trettin"><img src="../../assets/b3.png" style="width: 100%;"></a>
      </div>
   </div>
</div>



<!-- footerfoot -->

<div class="container-fluid footerfoot d-none d-sm-block"
   style="margin-top: 30px; padding-top: 50px; padding-bottom: 50px; color: white; font-size: 15px;">
   <div class="row">
      <div class="col-12">
         <div class="container">
            <div class="row">
               <div class="col-5">
                  Telefon
               </div>
               <div class="col-6">
                0511 93 68 113
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Telefax
               </div>
               <div class="col-6">
                  0511 93 68 113 9
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  E-Mail
               </div>
               <div class="col-6">
                  info@trettin-sanitätshaus.de
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Inhaber
               </div>
               <div class="col-6">
                  Dr. Arne Trettin e.K.
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  USt-IdNr.
               </div>
               <div class="col-6">
                  DE297496677
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Handelsregister
               </div>
               <div class="col-6">
                  Amtsgericht Hannover, HRA 205090
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Datenschutzbeauftragter
               </div>
               <div class="col-6">
                  Herr Walter Pinkernell <br>
                  walter.pinkernell@t-online.de
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Berufshaftpflichtversicherung
               </div>
               <div class="col-6">
                  Württembergische Versicherung AG <br>
                  70163 Stuttgart
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Streitbeilegung
               </div>
               <div class="col-6">
                  Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier
                  finden https://ec.europa.eu/consumers/odr/.
                  Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir
                  nicht verpflichtet und nicht bereit.
               </div>
            </div>
         </div>
      </div>
   </div>
</div>


<div class="container-fluid footerfoot d-block d-sm-none"
   style="margin-top: 30px; padding-top: 50px; padding-bottom: 50px; color: white; font-size: 15px;">
   <div class="row">
      <div class="col-12">
         <div class="container">
            <div class="row">
               <div class="col-5">
                  Telefon
               </div>
               <div class="col-6">
                0511 93 68 113
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Telefax
               </div>
               <div class="col-6">
                  0511 93 68 113 9
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  E-Mail
               </div>
               <div class="col-6">
                  info@trettin-sanitätshaus.de
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Inhaber
               </div>
               <div class="col-6">
                  Dr. Arne Trettin e.K.
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  USt-IdNr.
               </div>
               <div class="col-6">
                  DE297496677
               </div>
            </div>
            <div class="row">
               <div class="col-5">
                  Handelsregister
               </div>
               <div class="col-6">
                  Amtsgericht Hannover, HRA 205090
               </div>
            </div>
            <div class="row" style="padding-top: 20px;">
               <div class="text-left col-12">
                  Datenschutzbeauftragter
               </div>
            </div>
            <div class="row">
               <div class="text-left col-2">
               </div>
               <div class="text-left col-10">
                  Herr Walter Pinkernell <br>
                  walter.pinkernell@t-online.de
               </div>
            </div>
            <div class="row" style="padding-top: 20px;">
               <div class="text-left col-12">
                  Berufshaftpflichtversicherung
               </div>
            </div>
            <div class="row">
               <div class="col-2">
               </div>
               <div class="col-10">
                  Württembergische Versicherung AG <br>
                  70163 Stuttgart
               </div>
            </div>
            <div class="row" style="padding-top: 20px;">
               <div class="text-left col-12">
                  Streitbeilegung
               </div>
            </div>
            <div class="row">
               <div class="col-2">

               </div>
               <div class="col-10">
                  Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier
                  finden https://ec.europa.eu/consumers/odr/.
                  Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir
                  nicht verpflichtet und nicht bereit.
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
